// Latex.tsx
import React from 'react';
import styled, { css } from 'styled-components';
import Fonts from '../common/Fonts';
import Colors from '../common/Colors';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';

const StyledMarkdownContainer = styled.div<{ $fontSize?: number; $padding?: string }>`
  width: 100%;
  height: 100%;

  font-family: ${Fonts.quicksandMedium.fontFamily};
  font-weight: ${Fonts.quicksandMedium.fontWeight};
  font-size: ${props => props.$fontSize}px;
  background-color: ${Colors.transparent};
  padding: ${props => props.$padding};
  text-align: left;
  white-space: normal;

  .katex, .katex-html {
    font-size: ${props => props.$fontSize}px;
    font-family: ${Fonts.quicksandMedium.fontFamily} !important;
    font-weight: ${Fonts.quicksandMedium.fontWeight};
    letter-spacing: normal;
    font-style: normal;
    white-space: normal;
    line-height: 1.4;
  }

  .katex * {
    font-family: initial !important;
    font-weight: initial !important;
    font-style: initial !important;
    line-height: initial !important;
    letter-spacing: initial !important;
  }

  code {
    font-family: ${Fonts.quicksandMedium.fontFamily} !important;
    font-size: ${props => props.$fontSize}px;
    font-weight: ${Fonts.quicksandMedium.fontWeight};
    line-height: inherit !important;
    background-color: transparent;
    white-space: pre-wrap;
  }

  .katex .mopen, .katex .mclose {
    color: inherit;
    font-family: KaTeX_Main !important;
  }

  .katex .mord {
    font-family: ${Fonts.quicksandMedium.fontFamily} !important;
    font-weight: ${Fonts.quicksandMedium.fontWeight} !important;
  }

  .katex .mopen, 
  .katex .mclose,
  .katex .delimsizing,
  .katex .delimcenter {
    font-family: KaTeX_Size1, KaTeX_Main !important;
    padding: 0px 0.02em;
  }

  /* Specific styling for braces */
  .katex .delimsizing.size3 {
    font-family: KaTeX_Size3 !important;
  }
  .katex .delimsizing.size4 {
    font-family: KaTeX_Size4 !important;
  }
  .katex .delim-size1 > span {
    font-family: KaTeX_Size1 !important;
  }
  .katex .delim-size2 > span {
    font-family: KaTeX_Size2 !important;
  }
  .katex .delim-size3 > span {
    font-family: KaTeX_Size3 !important;
  }
  .katex .delim-size4 > span {
    font-family: KaTeX_Size4 !important;
  }
`;

interface LatexProps {
  content: string;
  fontSize?: number;
  padding?: string;
}

const Latex: React.FC<LatexProps> = ({ content, fontSize = 24, padding = '0px 30px' }) => {
    if (!content) return null;

    const convertedContent = convertLatexDelimiters(content);
    // console.log('CONTENT', JSON.stringify(content));
    // console.log('convertedContent', JSON.stringify(convertedContent));

    function convertLatexDelimiters(content: string): string {
        try {
          content = content?.replace(/\\\((.*?)\\\)/g, '$$$1$$'); // Convert inline math delimiters
          content = content?.replace(/\\\[([\s\S]*?)\\\]/g, '$$$$$$$1$$$$$$'); // Convert display math delimiters

          // Convert cases to matrix with explicit left and right braces
          content = content?.replace(/\\begin\{cases\}([\s\S]*?)\\end\{cases\}/g, '\\left\\{\\begin{matrix}$1\\end{matrix}\\right\\}');
          return content;
        } catch (error) {
            console.error('Error converting LaTeX delimiters:', error);
            return content;
        }
    };

    const katexOptions: KatexOptions = {
        output: 'html',
        minRuleThickness: 0.08,
        throwOnError: false,
        strict: false,
        displayMode: true,
        trust: true,
      };

    return (
    <StyledMarkdownContainer $fontSize={fontSize} $padding={padding}>
        <ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[[rehypeKatex, katexOptions]]}>
        {convertedContent}
        </ReactMarkdown>
    </StyledMarkdownContainer>
    );
};

export default Latex;
  
