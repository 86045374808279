// NumberBar.tsx
import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import Fonts from '../common/Fonts';
import Colors from '../common/Colors';
import { useHover } from '../HoverContext';
import Dragger from './interactives/Dragger';
import Stepper from './interactives/Stepper';
import { getMaxDecimalPlaces, wrapText } from '../../utils/beautifyText';
import { convertAllTypes } from '../../utils/convertTypes';

export interface NumberBarProps {
  values: number[];
  totalValue: number;
  labels?: string[];
  tickPerValue?: number[];
  tick?: number;
  colors?: string[];
  direction?: 'left' | 'right';

  draggable?: boolean[];
  dragTick?: number;
  dragOverrideOtherValues?: boolean;
  tutorial?: 'top-right' | 'top-left' | 'bottom-left' | 'bottom-right' | null;

  steppable?: 'total' | 'tick' | null;
  stepMin?: number;
  stepMax?: number;
  stepUp?: string;
  stepDown?: string;

  totalValueInitial?: number;
  tickInitial?: number;
  onStateChange?: (state: NumberBarProps, skipLogic?: string) => void;
}

const numberBarPropsTypeDefinition: NumberBarProps = {
  values: [0],
  totalValue: 0,
  labels: [''],
  tick: 0,
  tickPerValue: [0],
  colors: [''],
  direction: 'left',

  draggable: [false],
  dragTick: 0,
  
  steppable: 'total',
  stepMin: 0,
  stepMax: 0,

  totalValueInitial: 0,
  tickInitial: 0,
  onStateChange: () => {},
};

const NumberBar: React.FC<NumberBarProps> = (props) => {
  props = convertAllTypes(props, numberBarPropsTypeDefinition);
  let {values, totalValue = 100, labels, tick, tickPerValue, colors, direction = 'left', 
    draggable, dragTick, dragOverrideOtherValues, tutorial,steppable, stepMin, stepMax, stepUp, stepDown, 
    totalValueInitial, tickInitial, onStateChange} = props;

  // useEffect(() => {
  //   console.log('numberBar values', values);
  //   console.log('numberBar total value', totalValue);
  //   console.log('numberBar tick initial', tickInitial);
  //   console.log('numberBar totalValue initial', totalValueInitial);
  // }, [values, totalValue, tickInitial, totalValueInitial]);

  const svgRef = useRef<SVGSVGElement | null>(null);
  const [hasInteracted, setHasInteracted] = useState(false);
  const { handleMouseEnter, handleMouseLeave } = useHover();
  const [decimalPrecision, setDecimalPrecision] = useState(() => getMaxDecimalPlaces([
    ...(tick !== undefined ? [tick] : []), 
    ...(dragTick !== undefined ? [dragTick] : []), 
    ...values, 
    totalValue
  ]));
  // console.log('decimal precision', decimalPrecision);

  if (draggable?.some(d => d) && dragTick === undefined && tick !== undefined) {
    dragTick = tick;
  }

  const updateState = (newValuesData: number[], newTotalValueData: number, newTickData?: number, skipLogic?: string) => {
    // console.log(`NUMBERBAR newValuesData: ${newValuesData}, newTotalValueData: ${newTotalValueData}, newTickData: ${newTickData}`);
    values = newValuesData;
    totalValue = newTotalValueData;
    tick = newTickData;

    if (skipLogic) setHasInteracted(true);
    // console.log('skip logic', skipLogic);
    if (onStateChange) {
      onStateChange({
        ...props,
        values: newValuesData,
        totalValue: newTotalValueData,
        tick: newTickData,
        labels,
        colors,
        direction,
        draggable,
        dragTick,
        steppable,
      }, skipLogic);
    }
  }; 

  const handleDrag = (index: number, newValue: number) => {
    const updatedValues = [...values];
    updatedValues[index] = newValue;
    // console.log('drag values:', updatedValues);
    const skipLogic = `values[${index}]`;
    // console.log('skip logic', skipLogic);
    updateState(updatedValues, totalValue, tick, skipLogic);
  };

  const handleStepperClick = (newValue) => {
    // console.log('number bar stepper click', newValue);
    if (steppable === 'total') {
      updateState(values, newValue, tick, 'totalValue');
    } else if (steppable === 'tick') {
      updateState(values, totalValue, newValue, 'tick');
    }
  };

  // useEffect(() => {
  //   console.log('total', totalValue, 'tick every', tick, 'tick value', totalValue/tick);
  // }, [totalValue, tick]);

  const render = () => {
    if (!svgRef.current) return;

    let width = 700;
    const height = 80;
    const maxValue = totalValue;
    const tickInterval = tick ? (width / maxValue) * tick : 0;
    const padding = { left: 20, right: steppable ? 100 : 20, top: 20, bottom: 20 };

    const svg = d3.select(svgRef.current)
      .attr('viewBox', `0 0 ${width + padding.left + padding.right} ${height + padding.top + padding.bottom}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .style('width', '100%')
      .style('height', '100%');

    svg.selectAll('*').remove();

    // Value bars
    let accumulatedWidth = direction === 'left' ? padding.left : padding.left + width;
    const draggerData = [];
    values.forEach((value, index) => {
      const barWidth = (value / maxValue) * width;
      const barX = direction === 'left' ? accumulatedWidth : accumulatedWidth - barWidth;
        accumulatedWidth = direction === 'left' ? accumulatedWidth + barWidth : accumulatedWidth - barWidth;
      const color = colors && colors[index] ? colors[index] : Colors.vizDefault;

      // Value bar
      svg.append('rect')
        .attr('x', barX)
        .attr('y', padding.top)
        .attr('width', barWidth)
        .attr('height', height)
        .attr('fill', color)
        .attr('stroke', steppable ? 'none' : 'black')
        .attr('vector-effect', 'non-scaling-stroke')
        .attr('stroke-width', 1)
        // .attr('rx', 1)
        // .attr('ry', 1)
        // .attr('stroke-width', 3)
        .on('click', () => {
          handleMouseEnter(color);
        });

      // Tick per value
      if (tickPerValue && tickPerValue[index] && tickPerValue[index] > 0) {
        console.log('tickPerValue', tickPerValue[index]);
        const tickCount = Math.floor(value / tickPerValue[index]);
        for (let i = 1; i < tickCount; i++) {
          const tickIntervalForValue = tickPerValue[index] ? (width / maxValue) * tickPerValue[index] : 0;
          const tickX = barX + (i * tickIntervalForValue);
          svg.append('line')
            .attr('x1', tickX)
            .attr('x2', tickX)
            .attr('y1', padding.top)
            .attr('y2', height + padding.top)
            .attr('vector-effect', 'non-scaling-stroke')
            .attr('stroke', 'black')
            .attr('stroke-width', 1)
            .attr('opacity', 0.25);
        }
      }

      // Dragger data
      if (draggable && draggable[index]) {
        draggerData.push({
          id: `number-bar-segment-${index}`,
          x: direction === 'left' ? barX + barWidth : barX,
          y: padding.top + height / 2,
          color,
          index,
          value: values[index],
          tutorial: hasInteracted ? null : tutorial!,
        });
      }
    });

    // Tick lines
    if (tick && tick > 0 && tick !== totalValue) {
      for (let i = 0; i <= maxValue; i += tick) {
        const x = direction === 'left'
          ? (i / maxValue) * width + padding.left
          : width - (i / maxValue) * width + padding.left;
        svg.append('line')
          .attr('x1', x)
          .attr('x2', x)
          .attr('y1', padding.top)
          .attr('y2', height + padding.top)
          .attr('vector-effect', 'non-scaling-stroke')
          .attr('stroke', 'black')
          .attr('stroke-width', 1)
          .attr('opacity', 1.0);
      }
    }

    // Thick black outline
    svg.append('rect')
      .attr('x', padding.left)
      .attr('y', padding.top)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'none')
      .attr('rx', 1)
      .attr('ry', 1)
      .attr('vector-effect', 'non-scaling-stroke')
      .attr('stroke', 'black')
      .attr('stroke-width', 3);

    // Label text
    values.forEach((value, index) => {
      const barWidth = (value / maxValue) * width;
      const barX = direction === 'left' 
        ? padding.left + values.slice(0, index).reduce((acc, cur) => acc + (cur / maxValue) * width, 0)
        : padding.left + width - values.slice(0, index).reduce((acc, cur) => acc + (cur / maxValue) * width, 0) - barWidth;
      const color = colors && colors[index] ? colors[index] : Colors.vizDefault;
    
      if (labels && labels[index] && barWidth > 0) {
        const minLabelWidth = 120;
        const labelWidth = Math.max(barWidth-10, minLabelWidth);

        const insideLabel = svg.append('text')
          .attr('x', barX + barWidth / 2)
          .attr('y', height / 2 + padding.top)
          .style('font-family', Fonts.quicksandMedium.fontFamily)
          .style('font-weight', Fonts.quicksandMedium.fontWeight)
          .style('font-size', '24px')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('dominant-baseline', 'middle')
          .text(labels[index]);

        // Apply text wrapping
        wrapText(insideLabel, labelWidth, 'middle', 2);

        // After wrapping, adjust vertical position based on number of lines
        const numLines = insideLabel.selectAll('tspan').size();
        const lineHeight = 0.8;
        insideLabel.selectAll('tspan')
          .attr('x', barX + barWidth / 2)
          .attr('dy', (d, i) => {
            const startOffset = -(numLines - 1) * lineHeight / 2;
            return i === 0 ? `${startOffset}em` : `${lineHeight}em`;
          });

        if (value !== 0) {
          // Draw background box for each line of text independently
          insideLabel.selectAll('tspan').each(function() {
            const tspan = d3.select(this);
            const bbox = (this as SVGTSpanElement).getBBox();
            svg.insert('rect', 'text')
              .attr('x', bbox.x)
              .attr('y', bbox.y)
              .attr('rx', 4)
              .attr('ry', 4)
              .attr('width', bbox.width)
              .attr('height', bbox.height)
              .attr('fill', color)
              .attr('opacity', 1.0);
          });
        }

        insideLabel.on('click', () => {
          handleMouseEnter(color);
        });
      }
    });

    // Draggers on top
    draggerData.forEach(({ id, x, y, color, index, value, min, max, tutorial }) => {
      Dragger({
        svgRef: svgRef,
        id,
        color: dragOverrideOtherValues ? Colors.black : color,
        direction: direction === 'left' ? 'right' : 'left',
        x,
        y,
        minPos: 0 + padding.left,
        maxPos: 800 - padding.right,
        value: value,
        minVal: 0,
        maxVal: totalValue,
        tick: dragTick,
        decimalPrecision: decimalPrecision,
        prevSumValue: index > 0 ? values.slice(0, index).reduce((acc, cur) => acc + cur, 0) : 0,
        postSumValue: index + 1 < values.length ? values.slice(index + 1).reduce((acc, cur) => acc + cur, 0) : 0,
        dragOverrideOtherValues: dragOverrideOtherValues,
        tutorial: tutorial,
        handleDrag: (newValue) => handleDrag(index, newValue)
      });
    });

    // Stepper on top
    if (steppable) {
      const stepperX = width + padding.left + padding.right / 2;
      const stepperY = height / 2;

      const valueProp = steppable === 'total' ? totalValue : tick;
      const sumValueData = values.reduce((acc, value) => acc + value, 0);
      const maxTicks = 20;
      let minProp = steppable === 'total' ? Math.max(sumValueData, tickInitial ?? 0) : tickInitial ?? 0;
      let maxProp = steppable === 'total' ? maxTicks * (tickInitial ?? 1) : totalValueInitial;
      if (stepMin) minProp = stepMin;
      if (stepMax) maxProp = stepMax;
      const stepTick = tick ?? 1;

      // console.log('total value', totalValue, 'tick', tick);
      // console.log('initial total value', totalValueInitial, 'initial tick', tickInitial);
      // console.log('stepper', valueProp, 'sumValueData', sumValueData, 'tick', tick);
      // console.log('minProp', minProp, 'maxProp', maxProp);
      // console.log(`stepUp ${stepUp} stepDown ${stepDown}`);
      // console.log('------');

      Stepper({
        svgRef: svgRef.current!,
        x: stepperX,
        y: stepperY,

        value: valueProp,
        min: minProp,
        max: maxProp,
        tick: stepTick,
        tickInitial: tickInitial,
        decimalPrecision: decimalPrecision,
        showLabel: true,

        stepUp: stepUp!,
        stepDown: stepDown!,

        onClick: (newValue) => handleStepperClick(newValue)
      });
    }
  };

  useEffect(() => {
    render();
  }, [values, totalValue, labels, tick, colors, draggable, direction, handleMouseEnter, handleMouseLeave, onStateChange]); 

  return <svg ref={svgRef}></svg>;
};

export default NumberBar;
