// Problem.tsx
import React, { FunctionComponent, useState, useCallback, useEffect, useLayoutEffect, useRef, useMemo } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import Colors, { getComplementaryColors } from "../components/common/Colors";
import Fonts from "../components/common/Fonts";
import { useHover } from '../components/HoverContext';
import { useGetUserInfo } from "../apollo/useAuth";
import { useComputeQuestionResponse, useGetQuestionResponseById, useGetAllProblems } from "../apollo/useQuestion";
import { transformInitialProblemFormat } from "../components/ProblemTransform";
import LoadingSpinner from "../components/basic/LoadingSpinner";
import ProblemContent from "../components/ProblemContent";
import TopBar from "../components/TopBar";
import Menu from '../components/Menu';
import { useMediaQuery, useTheme } from '@mui/material';
import Scan from "../components/Scan";
import DebugOverlay from "../internal/DebugOverlay";
import { ProblemStateProvider } from "../components/ProblemState";
import { getIsLoggedIn } from "../apollo/useAuth";
import { useComputedProblems } from "../components/ComputedProblemsContext";

import Example from '../json/Example.json';
// import Eval from '../json/eval/Eval.json';
// import Hard from '../json/Hard.json';
// import AI from '../json/AI-Static.json';
// import YC from '../json/YC.json';
// import Template from '../internal/generate/Template.json';
// import Generate from '../internal/generate/Generate.json';
import Dev from '../json/Dev.json';
import Logic from '../json/Logic.json';
import Generate from "../internal/generate/generate.json";

const Root = styled.div<{ $mode: string, $isMenuOpen: boolean, $isMobile: boolean, $inputMode: 'Type' | 'Scan', $bottomOffset: number }>`
  width: 100%;
  min-height: 100svh;
  max-height: 100svh;
  position: relative;
  background-color: ${({ $mode, $inputMode }) => 
    $mode === 'new' && $inputMode === 'Scan' ? Colors.grey8 
    : $mode === 'new' && $inputMode === 'Type' ? Colors.background 
    : Colors.background};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 24px;
  color: ${Colors.grey8};
  font-family: ${Fonts.quicksandMedium.fontFamily};
  font-weight: ${Fonts.quicksandMedium.fontWeight};
  padding-left: ${({ $isMenuOpen, $isMobile }) => ($isMenuOpen && !$isMobile ? '300px' : '0')};
  transition: padding-left 0.3s ease;
  overflow: hidden;

  ${({ $mode }) => $mode === 'example' && `
    animation: slideInFromBottom 0.3s ease-out;
  `}
  @keyframes slideInFromBottom {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const ScrollContainer = styled.div<{ $mode: string, $bottomOffset: number, $isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  gap: ${({ $mode }) => $mode === 'example' ? '30px' : '0px'};
  margin: 0px;
  padding: 10px;
  padding-bottom: ${({ $isMobile, $bottomOffset }) => $isMobile ? `${$bottomOffset}px` : '10px'};
  max-height: ${({ $isMobile, $bottomOffset }) => $isMobile ? `calc(100vh - ${$bottomOffset}px)` : '100%'};
  min-height: 0;
`;

const Problem: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const debugParam = searchParams.has("debug");

  const DEBUG = debugParam;
  const LOCAL_PROBLEMS = false;
  const jsonSource = null; // Eval, Dev, Hard, YC, Logic, AI, Generate
  const EXAMPLE_IN_MENU = false;
  
  const { data: userInfo, loading: userInfoLoading, error: userInfoError } = useGetUserInfo();
  const { problemId } = useParams<{ problemId: string }>();
  const [loaded, setLoaded] = useState(false);
  const [problemLoaded, setProblemLoaded] = useState(false);
  const { data: questionData, loading: questionLoading, error: questionError, fetchQuestionById, fetchRawJsonById } = useGetQuestionResponseById();
  const { data: computeData, loading: computeLoading, error: computeError, computeResponse } = useComputeQuestionResponse();
  const { data: backendAllData, loading: allProblemsLoading, error: allProblemsError, fetchAllProblems, refetch: refetchAllProblems } = useGetAllProblems();
  const [menuProblems, setMenuProblems] = useState([]);
  const [currentProblem, setCurrentProblem] = useState(null);
  const [rawProblemJson, setRawProblemJson] = useState(null); 
  const [allProblemCount, setAllProblemCount] = useState(0);
  const [todayProblemCount, setTodayProblemCount] = useState(0);
  const [isComputingProblem, setIsComputingProblem] = useState(false);

  const [mode, setMode] = useState<'new' | 'example' | 'solution'>();
  const [inputMode, setInputMode] = useState<'Type' | 'Scan'>('Type'); 
  const [inputValue, setInputValue] = useState('');
  const [bottomOffset, setBottomOffset] = useState(0);

  const rootRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuOffset, setMenuOffset] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleMouseLeave } = useHover();
  const [submitClicked, setSubmitClicked] = useState(false); 

  const NUM_NOT_LOGGED_IN_PROBLEMS = 1;
  const { computedProblemIds, setComputedProblemIds, copyProblemsToAccount } = useComputedProblems();
  const promptLogin = !getIsLoggedIn() && (computedProblemIds?.length >= NUM_NOT_LOGGED_IN_PROBLEMS || submitClicked);
  
  const transformedJsonProblems = useMemo(() => {
    const problems = (jsonSource?.PROBLEMS || []).map(problem => {
      const transformedProblem = transformInitialProblemFormat(problem);
      // console.log('Transformed JSON problem data:', transformedProblem);
      return transformedProblem;
    });
    return problems;
  }, [jsonSource]);
  
  const transformedExampleProblems = useMemo(() => {
    if (Example) {
      let problems = Example.PROBLEMS || [];
      problems = problems.map(problem => {
        const transformedProblem = transformInitialProblemFormat(problem);
        // console.log('Transformed example problem data:', transformedProblem);
        return transformedProblem;
      });
      // console.log("Finished transformation for example problems...");
      return problems;
    }
    return [];
  }, [Example, jsonSource, transformedJsonProblems]);
  
  const exampleProblems = useMemo(() => {
    const hasDisplayProperty = transformedExampleProblems.some(problem => 'display' in problem);
    const filteredProblems = hasDisplayProperty 
      ? transformedExampleProblems.filter(problem => problem?.display !== false)
      : transformedExampleProblems;
    // console.log('Final example problems:', filteredProblems);
    return filteredProblems;
  }, [transformedExampleProblems]);
  
  const jsonProblems = useMemo(() => {
    const filteredProblems = transformedJsonProblems;
    // console.log('Final JSON problems (after filtering):', filteredProblems);
    return filteredProblems;
  }, [transformedJsonProblems]);  

  useEffect(() => { 
    if (!menuProblems[0]?.id) {
      fetchAllProblemsForMenu();
    }
  }, [menuProblems[0]]); // init menu problems

  const fetchAllProblemsForMenu = async() => {
    try {
      const backendAllProblems = await refetchAllProblems();
      // console.log('fetchAllProblemsForMenu', backendAllProblems);
      let allProblems = LOCAL_PROBLEMS ? [...jsonProblems] : [...backendAllProblems];
      if (EXAMPLE_IN_MENU) {
        allProblems = [...allProblems, ...exampleProblems];
      }
      setMenuProblems(allProblems);
      setAllProblemCount(allProblems.length);
      // console.log("Menu problems set", allProblems.length, allProblems);
    } catch (error) {
      console.error("Error fetching all questions:", error);
    }
  };

  useLayoutEffect(() => {
    if (!loaded) {
      setLoaded(true);
      // console.log("UI loaded");
    }
  }, [loaded]);

  useEffect(() => {
    setMenuOffset(menuOpen && !isMobile ? 300 : 0);
  }, [menuOpen, isMobile]);  

  useLayoutEffect(() => {
    if (rootRef.current) {
      const { left, top, right, bottom } = rootRef.current.getBoundingClientRect();
      // console.log('RootRef Positions - Left:', left, 'Top:', top, 'Right:', right, 'Bottom:', bottom);
    }
  }, [rootRef, mode, inputMode, bottomOffset]);
  
  // useLayoutEffect(() => {
  //   console.log('mode: ', mode);
  //   console.log('input mode: ', inputMode);
  //   console.log('bottom offset: ', bottomOffset);
  //   console.log('inner: ', window.innerHeight, window.innerWidth);
  //   console.log('viewport: ', window.visualViewport.height, window.visualViewport.width);
  // }, [mode, inputMode, bottomOffset, window]);

  useLayoutEffect(() => {
    const handleOffsetChange = () => {
      const offset = window.innerHeight - window.visualViewport.height;
      // Add a small buffer to prevent extra blank space
      const adjustedOffset = Math.max(0, offset - 40);
      setBottomOffset(adjustedOffset);
      // console.log('Bottom offset:', offset);
    };
    
    const handleViewportChange = () => {
      if (window.visualViewport) {
        handleOffsetChange();
      }
    };
  
    const handleInputFocus = () => {
      if (window.visualViewport) {
        requestAnimationFrame(() => {
          handleOffsetChange();
        });
      }
    };
  
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleViewportChange);
      window.visualViewport.addEventListener('scroll', handleViewportChange);
    }
  
    window.addEventListener('focus', handleInputFocus, true);
    window.addEventListener('blur', handleInputFocus, true);
  
    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleViewportChange);
        window.visualViewport.removeEventListener('scroll', handleViewportChange);
      }
      window.removeEventListener('focus', handleInputFocus, true);
      window.removeEventListener('blur', handleInputFocus, true);
    };
  }, []);  

  useLayoutEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        // console.log('ResizeObserver entry:', entry.target.id, entry.contentRect);
      }
    });
    const rootContainer = document.querySelector('#rootContainer');
    if (rootContainer) observer.observe(rootContainer);
    return () => {
      if (rootContainer) observer.unobserve(rootContainer);
      observer.disconnect();
    };
  }, []);
  
  const handleInputChange = (value: string) => {
    // console.log('handleInputChange', value);
    setInputValue(value);
  };

  const handleSubmitClick = useCallback((text: string, imageIds?: string[]) => {
    if (getIsLoggedIn() || computedProblemIds?.length < 1) {
      // Set flag when starting computation
      setIsComputingProblem(true);
      setProblemLoaded(false);
      setMode('solution');
      navigate(`/problem/new${debugParam ? '?debug=true' : ''}`, { replace: false });
      setInputValue(text);
      handleMouseLeave();
      doSubmitProblem(text, imageIds);
    } else {
      setSubmitClicked(true);
    }
  }, [inputValue]);

  const doSubmitProblem = useCallback(async (problemText: string, imageIds?: string[]) => {
    // console.log('doSubmitProblem', problemText, imageIds);
    try {
      // console.log('compute props', problemText, imageIds);
      const computedResponse = await computeResponse(problemText, imageIds);
      // fake long compute delay
      // const computedResponse = await new Promise(resolve => setTimeout(resolve, 30000)).then(() => null);
      if (computedResponse) {
        // console.log("compute response", computedResponse);
        setCurrentProblem(computedResponse);
        setComputedProblemIds([...computedProblemIds, computedResponse.id]);
        // setInputValue(computedResponse.parts[0]?.text || '');
        navigate(`/problem/${computedResponse.id}${debugParam ? '?debug=true' : ''}`, { replace: true });
        setProblemLoaded(true);
        setIsComputingProblem(false);
        await fetchAllProblemsForMenu();
        setTodayProblemCount(todayProblemCount + 1);
      }
    } catch (error) {
      console.error("Error fetching question response:", error);
    }
  }, [computeResponse]);

  const handleScanResult = (scanText: string, imageIds?: string[]) => {
    // console.log('handleScanResult', scanText, imageIds);
    handleSubmitClick(scanText, imageIds);
  };

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.key === 'Enter' && !event.shiftKey && mode === 'new') {
  //       handleSubmitClick(inputValue);
  //     }
  //   };
  //   document.addEventListener('keydown', handleKeyDown);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [handleSubmitClick]);  

  const handleNewProblemClick = useCallback(() => {
    navigate(`/problem${debugParam ? '?debug=true' : ''}`, { replace: false });
    setMenuOpen(false);
    setInputValue('');
    setMode('new');
  }, [navigate]);

  const handleExampleProblemsClick = useCallback(() => {
    setMode('example');
    navigate(`/problem/example${debugParam ? '?debug=true' : ''}`, { replace: false });
    setMenuOpen(false);
  }, [navigate]);

  const handleClearHover = useCallback((event: React.MouseEvent) => {
    // Prevent clearing hover color if clicking on visualizations or expressions
    if (event.target instanceof HTMLElement && !event.target.closest('.no-clear-hover')) {
      handleMouseLeave(); // Clear hovered color
    }
  }, [handleMouseLeave]);

  // Memoize menu problem IDs
  const menuProblemIds = useMemo(() => 
    menuProblems.map(problem => problem.id),
    [menuProblems]
  );

  const fetchAndSetProblemById = useCallback(async (id: string | null) => {
    try {
      if (id === 'example') {
        setMode('example');
        setMenuOpen(false);
      } else {
        let selectedProblem = null;
        if (LOCAL_PROBLEMS) {
          if (selectedProblem === null || selectedProblem === undefined) {
            if (EXAMPLE_IN_MENU) selectedProblem = exampleProblems.find(p => p.id === id);
            const response = jsonProblems.find(p => p.id === id);
            if (response) {
              selectedProblem = response;
              fetchAndSetRawProblemJson(id);
            }
          }
        } else {
          const response = await fetchQuestionById(id);
          if (response) {
            selectedProblem = response;
            fetchAndSetRawProblemJson(id);
          }
        }
        // console.log("selected problem", selectedProblem);
        
        if (selectedProblem) {
          // console.log('setting problem by id', selectedProblem);
          setCurrentProblem(selectedProblem);
          setMode('solution');
          setInputValue(selectedProblem.parts[0]?.text || '');
          setProblemLoaded(true);
          handleMouseLeave();
        } else if (selectedProblem && currentProblem === selectedProblem) {
          console.log('problem already loaded');
        } else {
          console.warn('Cannot find problem ID', id);
          resetProblemState();
          navigate(`/problem${debugParam ? '?debug=true' : ''}`, { replace: false });
        }
      }
    } catch (error) {
      console.error("Error fetching question by ID", error);
      resetProblemState();
    }
  }, [jsonProblems, fetchQuestionById, handleMouseLeave, navigate, menuProblemIds, copyProblemsToAccount]);
  
  const fetchAndSetRawProblemJson = async (id: string | null) => {
    if (DEBUG === true) {
      if (LOCAL_PROBLEMS) {
        return;
      } else {
        try {
          const rawJson = await fetchRawJsonById(id);
          if (rawJson) {
            if (rawJson.visualizations) {
              rawJson.visualizations = JSON.parse(rawJson.visualizations);
            }
            if (rawJson.expressions) {
              rawJson.expressions = JSON.parse(rawJson.expressions);
            }
            if (rawJson.debugFeedback) {
              rawJson.debugFeedback = JSON.parse(rawJson.debugFeedback);
            }
            if (rawJson.meta) {
              rawJson.meta = JSON.parse(rawJson.meta);
            }
            setRawProblemJson(rawJson);
          }
        } catch {
          console.error("Error fetching raw JSON by ID");
        }
      }
    }
  };

  // if the problem isn't already in the menu, copy it
  useEffect(() => {
    // console.log('allProblemsLoading', allProblemsLoading);
    if (currentProblem === null || menuProblemIds.length === 0 || allProblemsLoading) return;
    const shouldCopy = !menuProblemIds.includes(currentProblem.id);
    // console.log('menuProblemIds', menuProblemIds);
    // console.log('selectedProblem.id', currentProblem.id);
    // console.log('shouldCopy', shouldCopy);
    if (shouldCopy) {
      copyProblemsToAccount([currentProblem.id]);
    }
  }, [currentProblem, menuProblemIds, copyProblemsToAccount, allProblemsLoading]);
  
  const resetProblemState = () => {
    // console.log('resetting problem state');
    setCurrentProblem(null);
    setMode('new');
    setInputMode('Type');
    setInputValue('');
    setMenuOpen(false);
    handleMouseLeave();
  };

  useEffect(() => {
    if (problemId === 'new') {
      // Only redirect if we didn't get here through computation
      if (!isComputingProblem) {
        navigate(`/problem${debugParam ? '?debug=true' : ''}`, { replace: false });
        setMode('new');
      }
    } else if (problemId) {
      fetchAndSetProblemById(problemId);
    } else {
      resetProblemState();
    }
    // Reset the computation flag when leaving /new
    if (problemId !== 'new') {
      setIsComputingProblem(false);
    }
  }, [problemId]);

  const handleMenuOptionClick = useCallback((optionId) => {
    // console.log("menu option click", optionId);
    if (optionId !== problemId) {
      setMenuOpen(false);
      navigate(`/problem/${optionId}${debugParam ? '?debug=true' : ''}`, { replace: false });
      fetchAndSetProblemById(optionId);
    } else {
      setMenuOpen(false);
    }
  }, [navigate, problemId]);       

  const handleMenuClick = () => {
    // if (menuOpen === false) fetchAllProblemsForMenu();
    setMenuOpen(!menuOpen);
  };

  const handleTabSelect = (selectedTabName: string) => {
    // console.log('input mode: ', selectedTabName);
    setInputMode(selectedTabName as 'Type' | 'Scan');
    setMenuOpen(false);
  };

  const memoizedInitialData = useMemo(() => {
    if (mode === 'example') {
      return exampleProblems;
    } else if (currentProblem) {
      return [currentProblem];
    }
    return [];
  }, [mode, currentProblem, exampleProblems]);

  return (
    <Root id="rootContainer" ref={rootRef} data-unmask="true"
      $mode={mode} onClick={handleClearHover} $isMenuOpen={menuOpen} $isMobile={isMobile} $inputMode={inputMode} $bottomOffset={bottomOffset}>
       <ProblemStateProvider key={problemId} initialData={memoizedInitialData}>
      { !loaded && <LoadingSpinner /> }
      { loaded &&
        <>
          <TopBar 
            mode={mode} 
            problemId={problemId}
            handleNewProblemClick={handleNewProblemClick}
            handleMenuClick={handleMenuClick}
            handleTabSelect={handleTabSelect} 
            loggedIn={getIsLoggedIn()}
            userInfo={userInfo?.getUserInfo}
            isMobile={isMobile}
            menuOpen={menuOpen}
            inputMode={inputMode}
            promptLogin={promptLogin}
          />
          <ScrollContainer id="scrollContainer" key={problemId} $mode={mode} $bottomOffset={bottomOffset} $isMobile={isMobile}>
            {mode === 'example' ? (
              exampleProblems
                .map((exampleProblem, index) => (
                <ProblemContent
                  key={index}
                  inputValue={exampleProblem.parts[0]?.text || ''}
                  mode="example"
                  currentProblem={exampleProblem}
                  loading={!loaded}
                  error={false}
                  handleInputChange={() => {}}
                  handleSubmitClick={() => {}}
                  inputMode={inputMode}  
                  submitClicked={submitClicked}
                  userInfo={userInfo}
                  isLoggedIn={getIsLoggedIn()}
                  promptLogin={promptLogin}
                  bottomOffset={bottomOffset}
                />
              ))
            ) : (
              <>
                <ProblemContent 
                  inputValue={inputValue} 
                  mode={mode} 
                  currentProblem={currentProblem}
                  handleInputChange={handleInputChange}
                  loading={!problemLoaded}
                  error={false}
                  handleSubmitClick={() => handleSubmitClick(inputValue)}
                  handleExampleProblemsClick={handleExampleProblemsClick}
                  inputMode={inputMode} 
                  todayProblemCount={todayProblemCount}
                  submitClicked={submitClicked}
                  userInfo={userInfo}
                  isLoggedIn={getIsLoggedIn()}
                  promptLogin={promptLogin}
                  bottomOffset={bottomOffset}
                />
                {currentProblem && (
                  <DebugOverlay enableDebug={DEBUG} problem={currentProblem} 
                    problemsData={menuProblems} rawProblem={rawProblemJson} fetchAndSetProblemById={fetchAndSetProblemById} />
                )}
              </>
            )}
          </ScrollContainer>
          {mode === 'new' && inputMode === 'Scan' && (
            <Scan menuOffset={menuOffset} onScanComplete={handleScanResult} closeMenu={() => setMenuOpen(false)} />
          )}
        </>
      }
      <Menu
        onSelect={handleMenuOptionClick}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        isMobile={isMobile}
        selectedId={problemId}
        problemsData={menuProblems}
        userInfo={userInfo}
      />
      </ProblemStateProvider>
    </Root>
  );
};

export default Problem;