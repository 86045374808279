// Document.tsx
import React from 'react';
import styled, { css } from 'styled-components';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Fonts from '../common/Fonts';
import Colors from '../common/Colors';
import Latex from './Latex';

const StyledTextField = styled(TextField)<{ $readOnly: boolean; $multiline: boolean; $hasValue: boolean; $horizontalPadding: number }>`
  position: relative;
  flex-shrink: 0;

  .MuiInputBase-root {
    background-color: ${Colors.white};
    border-radius: 8px;
    font-family: ${() => `${Fonts.quicksandMedium.fontFamily}`};
    font-weight: ${() => `${Fonts.quicksandMedium.fontWeight}`};
    font-size: 24px;
    overflow: visible;
    position: relative;
    padding: ${({ $horizontalPadding }) => `${$horizontalPadding/2}px ${$horizontalPadding}px`};

    &:focus-within {
      &::after {
        content: '';
        position: absolute;
        left: ${({ $horizontalPadding }) => `${$horizontalPadding/2}px`};
        top: 5px;
        height: calc(100% - 10px);
        width: 2px;
        background-color: ${({ $readOnly }) => ($readOnly ? 'transparent' : Colors.grey2)};
        z-index: 2;
      }
    }
  }

  .MuiInputBase-input {
    position: relative;
    margin: 0px;
    padding: ${({ $horizontalPadding }) => `${$horizontalPadding/2}px ${$horizontalPadding}px`};
    white-space: ${({ $hasValue }) => ($hasValue ? 'pre-wrap' : 'nowrap')};
    text-overflow: clip;
    overflow: visible;
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: transparent !important;
    }
    &:hover fieldset {
      border-color: transparent !important;
    }
    &.Mui-focused fieldset {
      border-color: transparent !important;
    }

    ${({ $readOnly }) =>$readOnly && css`
        fieldset {
          border-color: transparent !important;
        }
        &:hover fieldset {
          border-color: transparent !important;
        }
    `}
  }
`;

const StyledTextareaAutosize = styled(TextareaAutosize)`
  position: relative;
  width: 100%;
  max-height: 100%;
  resize: none;
  overflow: none;
  font-family: ${() => `${Fonts.quicksandMedium.fontFamily}`};
  font-weight: ${() => `${Fonts.quicksandMedium.fontWeight}`};
  font-size: 24px;
  white-space: pre-wrap;
  }
`;

interface DocumentProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  multiline?: boolean;
  readOnly?: boolean;
  horizontalPadding?: number;
}

const Document: React.FC<DocumentProps> = ({
  placeholder = '',
  value,
  onChange,
  multiline = false,
  readOnly = false,
  horizontalPadding = 34,
}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('DOCUMENT Input Value:', event.target.value);
    onChange(event.target.value);
  };
  // console.log('document value', value);

  const containsLatex = /\$\$.*?\$\$|\\\(.*?\\\)|\\\[.*?\\\]/s.test(value);
  const containsMarkdown = /[*#\-_>`\[\]\\]/.test(value);
  // console.log('containsLatex', containsLatex);
  // console.log('containsMarkdown', containsMarkdown);
  
  if (readOnly && (containsLatex || containsMarkdown)) {
    return (
      <Latex content={value} fontSize={24} padding={`0px ${horizontalPadding}px`}/>
    );
  }

  return (
    <StyledTextField
      variant="outlined"
      placeholder={placeholder}
      value={value}
      onChange={handleOnChange}
      multiline={multiline}
      fullWidth
      autoComplete="off"
      InputProps={{
        inputComponent: StyledTextareaAutosize,
        readOnly: readOnly,
      }}
      $readOnly={readOnly}
      $multiline={multiline}
      $hasValue={Boolean(value)}
      $horizontalPadding={horizontalPadding/2}
    />
  );
};

export default Document;

