// CoordinatePlane.tsx
import React, { useRef, useEffect, useState, useCallback } from 'react';
import * as d3 from 'd3';
import Fonts from '../common/Fonts';
import Colors from '../common/Colors';
import { useHover } from '../HoverContext';
import Dragger from './interactives/Dragger';
import { getMaxDecimalPlaces } from '../../utils/beautifyText';
import { wrapText } from '../../utils/beautifyText';
import { convertAllTypes } from '../../utils/convertTypes';

interface AxisConfig {
  min?: number;
  max?: number;
  tick?: number;
  label?: string;
}

interface Point {
  x: number;
  y: number;
  name?: string;
  draggable?: boolean;
}

interface Dataset {
  points: Point[];
  line?: boolean;
  color?: string;
  label?: string;
}

export interface CoordinatePlaneProps {
  datasets: Dataset[];
  xAxis?: AxisConfig;
  yAxis?: AxisConfig;
  dragTick?: number;
  onStateChange?: (state: CoordinatePlaneProps, skipLogic?: string[]) => void;
}

const coordinatePlanePropsTypeDefinition: CoordinatePlaneProps = {
  datasets: [{
    points: [{ x: 0, y: 0, name: '' }]
  }],
  xAxis: { min: 0, max: 10, tick: 1, label: '' },
  yAxis: { min: 0, max: 10, tick: 1, label: '' },
  dragTick: 1,
  onStateChange: () => {},
};

const CoordinatePlane: React.FC<CoordinatePlaneProps> = (props) => {
  props = convertAllTypes(props, coordinatePlanePropsTypeDefinition);
  let { datasets, xAxis, yAxis, dragTick, onStateChange } = props;

  const svgRef = useRef<SVGSVGElement | null>(null);
  const { handleMouseEnter, handleMouseLeave } = useHover();

  const updateState = useCallback((newDatasets: Dataset[], skipLogic: string[]) => {
    datasets = newDatasets;
    if (onStateChange) {
      onStateChange({ ...props, datasets: newDatasets, xAxis, yAxis, dragTick }, skipLogic);
    }
  }, [onStateChange, xAxis, yAxis, dragTick]);

  const handleDrag = useCallback((datasetIndex: number, pointIndex: number, newX: number, newY: number) => {
      const newDatasets = [...datasets];
      if (newDatasets[datasetIndex]) {
        newDatasets[datasetIndex].points[pointIndex].x = newX;
        newDatasets[datasetIndex].points[pointIndex].y = newY;
      }
  
      const skipLogicX = `datasets[${datasetIndex}].points[${pointIndex}].x`;
      const skipLogicY = `datasets[${datasetIndex}].points[${pointIndex}].y`;
      const skipLogic = [skipLogicX, skipLogicY];
      // console.log(skipLogic, skipLogicX, skipLogicY);
      
      updateState(newDatasets, skipLogic);
      return newDatasets;
  }, [updateState, datasets]);  

  const render = () => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);

    const width = 400;
    const height = 400;
    const margin = datasets.some(dataset => dataset.label) 
      ? { top: 40, right: 40, bottom: 40, left: 100  } 
      : { top: 40, right: 40, bottom: 40, left: 40 };

    svg.attr('viewBox', `0 0 ${width} ${height}`)
       .attr('preserveAspectRatio', 'xMidYMid meet')
       .style('width', '100%')
       .style('height', '100%');

    const xConfig = {
      min: xAxis?.min,
      max: xAxis?.max,
      tick: xAxis?.tick,
    };

    const yConfig = {
      min: yAxis?.min,
      max: yAxis?.max,
      tick: yAxis?.tick,
    };

    const xScale = d3.scaleLinear().domain([xConfig.min, xConfig.max]).range([margin.left, width - margin.right]);
    const yScale = d3.scaleLinear().domain([yConfig.min, yConfig.max]).range([height - margin.bottom, margin.top]);

    svg.selectAll('*').remove();

    let quadrants = 1;
    if (xConfig.min < 0 && xConfig.max > 0) quadrants *= 2;
    if (yConfig.min < 0 && yConfig.max > 0) quadrants *= 2;

    const xAxisGroup = svg.append('g').attr('transform', `translate(0,${yScale(0)})`);
    if (xConfig.tick !== 0) {
      const xTicks = d3.range(xConfig.min, xConfig.max + xConfig.tick, xConfig.tick).filter(tick => tick <= xConfig.max);
      if (!xTicks.includes(xConfig.max)) xTicks.push(xConfig.max);
      xAxisGroup.call(d3.axisBottom(xScale).tickValues(xTicks).tickFormat(d3.format('d')))
        .selectAll('text')
        .style('font-family', Fonts.quicksandLight.fontFamily)
        .style('font-weight', Fonts.quicksandLight.fontWeight)
        .style('font-size', '18px');
      xAxisGroup.selectAll('.tick line').attr('stroke-width', 1);
      if (quadrants > 1) xAxisGroup.selectAll('.tick text').filter((d: number) => d === 0).remove();
    }
    xAxisGroup.selectAll('.domain').attr('stroke-width', 2);

    const yAxisGroup = svg.append('g').attr('transform', `translate(${xScale(0)},0)`);
    if (yConfig.tick !== 0) {
      const yTicks = d3.range(yConfig.min, yConfig.max + yConfig.tick, yConfig.tick).filter(tick => tick <= yConfig.max);
      if (!yTicks.includes(yConfig.max)) yTicks.push(yConfig.max);
      yAxisGroup.call(d3.axisLeft(yScale).tickValues(yTicks).tickFormat(d3.format('d')))
        .selectAll('text')
        .style('font-family', Fonts.quicksandLight.fontFamily)
        .style('font-weight', Fonts.quicksandLight.fontWeight)
        .style('font-size', '18px');
      yAxisGroup.selectAll('.tick line').attr('stroke-width', 1);
      if (quadrants > 1) yAxisGroup.selectAll('.tick text').filter((d: number) => d === 0).remove();
    }
    yAxisGroup.selectAll('.domain').attr('stroke-width', 2);

    if (xConfig.tick !== 0 || yConfig.tick !== 0) {
      svg.append('line')
        .attr('x1', xScale(xConfig.min))
        .attr('y1', yScale(0))
        .attr('x2', xScale(xConfig.max))
        .attr('y2', yScale(0))
        .attr('vector-effect', 'non-scaling-stroke')
        .attr('stroke-width', 2)
        .attr('stroke', 'black');

      svg.append('line')
        .attr('x1', xScale(0))
        .attr('y1', yScale(yConfig.min))
        .attr('x2', xScale(0))
        .attr('y2', yScale(yConfig.max))
        .attr('vector-effect', 'non-scaling-stroke')
        .attr('stroke-width', 2)
        .attr('stroke', 'black');
    }

    datasets.forEach((dataset, datasetIndex) => {
      const color = dataset.color || Colors.vizDefault;

      if (dataset.line) {
        svg.append('polygon')
          .attr('points', dataset.points.map((p) => `${xScale(p.x)},${yScale(p.y)}`).join(' '))
          .attr('fill', color)
          .attr('fill-opacity', 0.4)
          .attr('stroke', color)
          .attr('stroke-width', 3)
          .on('click', () => handleMouseEnter(color));
      }

      dataset.points.forEach((point, pointIndex) => {
        svg.append('circle')
          .attr('cx', xScale(point.x))
          .attr('cy', yScale(point.y))
          .attr('r', 6)
          .attr('fill', color)
          .attr('class', `dataset-${datasetIndex}`)
          .on('click', () => handleMouseEnter(color));

        if (point.draggable) {
          Dragger({
            svgRef,
            id: `dragger-${datasetIndex}-${pointIndex}`,
            color,
            direction: 'all',
            x: xScale(point.x),
            y: yScale(point.y),
            minPos: { x: xScale(xConfig.min), y: yScale(yConfig.max) },
            maxPos: { x: xScale(xConfig.max), y: yScale(yConfig.min) },
            value: { x: point.x, y: point.y },
            minVal: { x: xConfig.min, y: yConfig.min },
            maxVal: { x: xConfig.max, y: yConfig.max },
            tick: dragTick ? dragTick : null,
            decimalPrecision: dragTick ? getMaxDecimalPlaces([dragTick]) : null,
            handleDrag: (newX, newY) => handleDrag(datasetIndex, pointIndex, newX, newY)
          });
        }

        if (point.name) {
          svg.append('text')
            .text(point.name)
            .attr('font-family', Fonts.quicksandMedium.fontFamily)
            .attr('font-weight', Fonts.quicksandMedium.fontWeight)
            .attr('font-size', '18px')
            .attr('fill', color)
            .attr('x', xScale(point.x))
            .attr('y', yScale(point.y))
            .attr('text-anchor', 'middle')
            .attr('dy', '-1em');
        }
      });

      if (dataset.label) {
        svg.append('text')
          .text(dataset.label)
          .attr('font-family', Fonts.quicksandMedium.fontFamily)
          .attr('font-weight', Fonts.quicksandMedium.fontWeight)
          .attr('font-size', '18px')
          .style('line-height', '1.2em')
          .attr('fill', color)
          .attr('x', margin.left - 10)
          .attr('y', yScale(d3.mean(dataset.points, (d) => d.y) ?? 0))
          .attr('text-anchor', 'end')
          .attr('alignment-baseline', 'middle')
          .call(wrapText, margin.left, 'end')
          .on('click', () => handleMouseEnter(color));
      }
    });
  };
  
  useEffect(() => {
    render();
  }, [datasets, xAxis, yAxis, dragTick, handleMouseEnter, handleMouseLeave, handleDrag]);

  return <svg ref={svgRef}></svg>;
};

export default CoordinatePlane;
