// BarGraph.tsx
import React, { useRef, useEffect, useState, useMemo } from 'react';
import * as d3 from 'd3';
import Fonts from '../common/Fonts';
import { useHover } from '../HoverContext';
import Colors from '../common/Colors';
import Dragger from './interactives/Dragger';
import { wrapText, shortenText } from '../../utils/beautifyText';
import { getMaxDecimalPlaces } from '../../utils/beautifyText';
import { convertAllTypes } from '../../utils/convertTypes';

interface AxisConfig {
  min?: number;
  max?: number;
  tick?: number;
  label?: string;
}

export interface BarGraphProps {
  data: { name: string; value: number[]; color?: string[]; style?: string[]; draggable?: boolean[]; }[];
  xAxis?: AxisConfig;
  yAxis?: AxisConfig;
  dragTick?: number;
  onStateChange?: (state: BarGraphProps, skipLogic?: string) => void;
}

const barGraphPropsTypeDefinition: BarGraphProps = {
  data: [
    {
      name: '',
      value: [0],
      color: [''],
      style: [''],
      draggable: [false]
    }
  ],
  xAxis: {
    min: 0,
    max: 0,
    tick: 0,
    label: ''
  },
  yAxis: {
    min: 0,
    max: 0,
    tick: 0,
    label: ''
  },
  dragTick: 0,
  onStateChange: () => {},
};

const BarGraph: React.FC<BarGraphProps> = (props) => {
  props = convertAllTypes(props, barGraphPropsTypeDefinition);
  const { data, xAxis, yAxis, dragTick, onStateChange } = props;

  const svgRef = useRef<SVGSVGElement | null>(null);
  const { handleMouseEnter, handleMouseLeave, hoveredColor } = useHover();

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const barWidth = 80;
  const barSpacing = 20;
  const margin = { top: 40, right: 10, bottom: 80, left: 60 };

  // useEffect(() => {
  //   props.data.forEach((item, dataIndex) => {
  //     if (Array.isArray(item.value)) {
  //       item.value.forEach((value, valueIndex) => {
  //         console.log(`data[${dataIndex}].value[${valueIndex}]`, value);
  //       });
  //     }
  //   });
  // }, [props.data]);

  const normalizeSegmentValues = useMemo(() => (values) => {
    let cumulativeValue = 0;
    return values.map((v, i) => {
      cumulativeValue += v.value;
      const previousCumulativeValue = i > 0 ? values.slice(0, i).reduce((acc, cur) => acc + cur.value, 0) : 0;
      const subsequentCumulativeValue = i < values.length - 1 ? values.slice(i + 1).reduce((acc, cur) => acc + cur.value, 0) : 0;
      return {
        ...v,
        cumulativeValue,
        previousCumulativeValue,
        subsequentCumulativeValue
      };
    });
  }, []);

  const normalizeData = useMemo(() => (data: any) => data.map((item: any) => {
    const value = Array.isArray(item.value) ? item.value : [item.value];
    const color = item.color ? (Array.isArray(item.color) ? item.color : [item.color]) : new Array(value.length).fill(Colors.grey6);
    const draggable = item.draggable ? (Array.isArray(item.draggable) ? item.draggable : [item.draggable]) : new Array(value.length).fill(false);
  
    const valuesWithDetails = value.map((v: any, i: number) => ({
      value: v,
      color: color[i],
      style: item.style ? item.style[i] : 'fill',
      name: item.name,
      index: i,
      draggable: draggable[i]
    }));
  
    return {
      ...item,
      value: normalizeSegmentValues(valuesWithDetails)
    };
  }), [normalizeSegmentValues]);

  const normalizedData = useMemo(() => normalizeData(data), [data, normalizeData]);

  const effectiveDragTick = useMemo(() => {
    if (dragTick != null) {
      return dragTick;
    }
    const xAxisTick = xAxis?.tick ?? Infinity;
    const yAxisTick = yAxis?.tick ?? Infinity;
    return Math.min(xAxisTick, yAxisTick);
  }, [dragTick, xAxis?.tick, yAxis?.tick]);

  const decimalPrecision = useMemo(() => {
    const allValues = data.flatMap(item => Array.isArray(item.value) ? item.value : [item.value]);
    return getMaxDecimalPlaces(allValues);
  }, [data]);  

  const xScale = useMemo(() => {
    return d3.scaleBand()
      .domain(normalizedData.map((d: any) => d.name))
      .range([margin.left + barSpacing / 2, width - margin.right - barSpacing / 2])
      .padding(0.1);
  }, [normalizedData, margin.left, margin.right, barSpacing, width]);

  const yScale = useMemo(() => {
    return d3.scaleLinear()
      .domain([
        yAxis?.min ?? 0,
        yAxis?.max ?? (d3.max(normalizedData, (d: any) => {
          const sum = d3.sum(d.value.map((v: any) => v.value));
          return Math.round(sum * 1.1);
        }) || 0)
      ])
      .nice()
      .range([height - margin.bottom, margin.top]);
  }, [normalizedData, height, margin.bottom, margin.top, yAxis]);

  const updateState = (updatedData: typeof normalizedData, skipLogic?: string) => {
    const normalizedUpdatedData = updatedData.map(bar => ({
      ...bar,
      value: normalizeSegmentValues(bar.value),
    }));
  
    if (onStateChange) {
      const updatedState: BarGraphProps = {
        ...props,
        data: normalizedUpdatedData.map(bar => ({
          ...bar, 
          value: bar.value.map((segment: any) => segment.value),
          color: bar.value.map((segment: any) => segment.color),
          style: bar.value.map((segment: any) => segment.style),
          draggable: bar.value.map((segment: any) => segment.draggable),
        })),
      };
  
      // console.log('update state', updatedState);
      onStateChange(updatedState, skipLogic);
    }
  };
   
  const handleDrag = (barName: string, segmentIndex: number, newVal: number) => {
    let skipLogic = undefined;
    const updatedData = normalizedData.map(bar =>
      bar.name === barName
        ? {
            ...bar,
            value: bar.value.map((segment, i) =>
              i === segmentIndex ? { ...segment, value: newVal } : segment
            ),
          }
        : bar
    );
    const index = props.data.findIndex(d => d.name === barName);
    skipLogic = `data[${index}].value[${segmentIndex}]`;
    // console.log('skipLogic', skipLogic);
    updateState(updatedData, skipLogic);
  };  

  const renderAxes = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
    const xAxisElement = svg.append('g')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(xScale).tickSizeOuter(0));
  
    xAxisElement.selectAll('.tick')
      .attr('transform', function(d) {
        return `translate(${xScale(d as string) + barWidth / 2},0)`;
      });
  
    if (xAxis?.label) {
      svg.append('text')
        .attr('x', (width - margin.left - margin.right) / 2 + margin.left)
        .attr('y', height - 4)
        .attr('text-anchor', 'middle')
        .style('font-family', Fonts.quicksandLight.fontFamily)
        .style('font-weight', Fonts.quicksandLight.fontWeight)
        .style('font-size', '18px')
        .attr('fill', 'black')
        .text(xAxis.label);
    }
  
    xAxisElement.selectAll('text')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .attr('x', 0)
      .style('font-family', Fonts.quicksandMedium.fontFamily)
      .style('font-weight', Fonts.quicksandMedium.fontWeight)
      .style('font-size', '18px')
      .style('line-height', '1.2em')
      .style('fill', (d, i) => {
        const itemColors = normalizedData.find(bar => bar.name === d)?.value.map(segment => segment.color) ?? [Colors.grey6];
        const mainColor = itemColors[0];
        if (hoveredColor && !itemColors.includes(hoveredColor)) {
          return Colors.grey2;
        }
        return itemColors.length > 1 || mainColor === Colors.grey6 ? 'black' : mainColor;
      })
      .call(wrapText, xScale.bandwidth())
      // .call(shortenText, 8);
      // .on('click', function(event, d) {
      //   const bar = normalizedData.find(item => item.name === d);
      //   if (bar) {
      //     const color = bar.value[0].color;
      //     handleMouseEnter(color);
      //   }
      // });
  
    const yAxisElement = svg.append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScale).tickValues(yAxis?.tick ? d3.range(0, yAxis.max + yAxis.tick, yAxis.tick) : null));
  
    yAxisElement.selectAll('text')
      .style('font-family', Fonts.quicksandLight.fontFamily)
      .style('font-weight', Fonts.quicksandLight.fontWeight)
      .style('font-size', '18px');
  
    yAxisElement.selectAll('path')
      .style('stroke-width', '2px');
    yAxisElement.selectAll('.tick line')
      .style('stroke-width', '1px');
  
    if (yAxis?.label) {
      svg
        .append('text')
        .attr('x', -height / 2)
        .attr('y', margin.left / 3)
        .attr('text-anchor', 'middle')
        .attr('fill', 'black')
        .attr('transform', 'rotate(-90)')
        .style('font-family', Fonts.quicksandLight.fontFamily)
        .style('font-weight', Fonts.quicksandLight.fontWeight)
        .style('font-size', '18px')
        .text(yAxis.label);
    }
  };
  

  const renderBars = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
    const bars = svg.append('g')
      .selectAll('g')
      .data(normalizedData)
      .join('g')
      .attr('transform', (d: any) => `translate(${xScale(d.name)},0)`);

    bars.selectAll('rect')
      .data((d: any) => d.value)
      .join('rect')
      .attr('x', 0)
      .attr('y', (d: any) => yScale(d.cumulativeValue))
      .attr('height', (d: any) => {
        const height = yScale(0) - yScale(d.value);
        return height > 0 ? height : 0;
      })
      .attr('width', barWidth)
      .attr('fill', (d: any) => d.style === 'outline' ? 'none' : d.color)
      .attr('stroke', (d: any) => d.color)
      .attr('stroke-width', 2)
      .attr('vector-effect', 'non-scaling-stroke')
      .attr('shape-rendering', 'crispEdges')
      .attr('id', (d: any) => `${d.name}-segment-${d.index}-${d.color}`)
      .style('pointer-events', (d: any) => d.style === 'outline' ? 'all' : 'auto')
      // .on('click', (event, d: any) => {
      //   handleMouseEnter(d.color);
      // });
  };

  const renderDraggers = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
    const draggerGroup = svg.selectAll('.dragger-group')
      .data(normalizedData)
      .join('g')
      .attr('class', 'dragger-group')
      .attr('transform', (d: any) => `translate(${xScale(d.name)},0)`);
  
    draggerGroup.selectAll('.dragger')
      .data((d: any) => d.value)
      .join('g')
      .attr('class', 'dragger')
      .filter((d: any) => d.draggable)
      .each(function(d: any) {
        Dragger({
          svgRef: svgRef,
          color: d.color,
          direction: 'up',
          id: `${d.name}-segment-${d.index}-${d.color}`,
          x: xScale(d.name) + barWidth / 2,
          y: yScale(d.cumulativeValue),
          minPos: yScale.range()[1], 
          maxPos: yScale.range()[0],
          value: d.value,
          minVal: yAxis.min, 
          maxVal: yAxis.max,
          prevSumValue: d.previousCumulativeValue,
          postSumValue: d.subsequentCumulativeValue,
          tick: effectiveDragTick,
          decimalPrecision: decimalPrecision,
          showLabel: 'top',
          handleDrag: (newVal) => handleDrag(d.name, d.index, newVal),
        });
      });
  };  

  const renderLine = (svg) => {
    svg.append('line')
      .attr('x1', margin.left)
      .attr('x2', width - margin.right)
      .attr('y1', height - margin.bottom)
      .attr('y2', height - margin.bottom)
      .attr('stroke', 'black')
      .attr('stroke-width', 2);
  };

  const render = () => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const containerHeight = svgRef.current.clientHeight;
    const containerWidth = svgRef.current.clientWidth;
    setHeight(Math.max(containerHeight, 400));
    setWidth((normalizedData.length * (barWidth + barSpacing)) + margin.left + margin.right + barSpacing);

    svg.attr('viewBox', `0 0 ${width} ${height}`)
      .style('width', `${width}px`)
      .style('height', `auto`)
      .attr('preserveAspectRatio', 'xMidYMid meet');

    svg.selectAll('*').remove();

    renderAxes(svg);
    renderBars(svg);
    renderLine(svg);
    renderDraggers(svg);
  };

  useEffect(() => {
    render();
  }, [normalizedData, xAxis, yAxis, dragTick, handleMouseEnter, handleMouseLeave, hoveredColor]);

  return <svg ref={svgRef}></svg>;  
};

export default BarGraph;
