// TopBar.tsx
import React, { FunctionComponent, useState, MouseEvent } from 'react';
import styled from 'styled-components';
import Colors from '../components/common/Colors';
import Fonts from './common/Fonts';
import Button from '../components/basic/Button';
import ButtonLink from '../components/basic/ButtonLink';
import TabPanel from './basic/TabPanel';
import MenuOpen from './basic/MenuOpen';

const TopContainer = styled.div<{ mode: string, inputMode: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;
  background-color: ${Colors.transparent};
  z-index: 100;
  padding: 10px;
  gap: 10px;
  overflow: hidden;
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const Middle = styled.div`
  display: flex;
  height: 54.5px;
  margin: 0 auto;
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const Title = styled.div<{ $inputmode: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  color: ${props => props.$inputmode === 'Upload' ? Colors.white : Colors.grey8};
  font-size: 20px;
  font-family: ${Fonts.quicksandSemiBold.fontFamily};
  font-weight: ${Fonts.quicksandSemiBold.fontWeight};
  text-align: center;
  margin: 0;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface TopBarProps {
  mode: 'example' | 'new' | 'solution';
  problemId: string | undefined;
  handleNewProblemClick?: () => void;
  handleMenuClick?: () => void;
  handleTabSelect?: (selectedTabName: string) => void;
  handleUploadBackClick?: () => void;
  loggedIn?: boolean;
  userInfo?: any;
  isMobile: boolean;
  menuOpen: boolean;
  inputMode: 'Type' | 'Scan' | 'Upload';
  promptLogin: boolean;
}

const NEW_ACCOUNT_UI = false;

const TopBar: FunctionComponent<TopBarProps> = ({
  mode,
  problemId,
  handleNewProblemClick,
  handleMenuClick,
  handleTabSelect,
  handleUploadBackClick,
  loggedIn,
  userInfo,
  isMobile,
  menuOpen,
  inputMode,
  promptLogin,
}) => {
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);

  const onLoginClick = () => {
    console.log('onLoginClick');
  };

  const onSignUpClick = () => {
    console.log('onSignUpClick');
  };

  const onAccountClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAccountMenuAnchorEl(event.currentTarget);
    setAccountMenuOpen(!accountMenuOpen);
  };

  const accountMenuOptions = [
    {
      text: userInfo?.email,
      icon: "person",
    },
    {
      text: "Log Out",
      icon: "logout",
      onClick: () => console.log("Logout clicked"),
    },
  ];

  return (
    <TopContainer id="topContainer" mode={mode} inputMode={inputMode} key={inputMode}>
      {inputMode === 'Upload' ? (
        <>
          <Left>
            <Button size={'small'} icon={'left'} color={'ghost white'} onClick={handleUploadBackClick} />
          </Left>
          <Middle>
            <Title $inputmode={inputMode}>Crop one problem.</Title>
          </Middle>
          <Right></Right>
        </>
      ) : (
        <>
          <Left>
            <Button
              size={'small'}
              icon={!menuOpen ? 'menu' : 'delete'}
              color={mode === 'new' && inputMode === 'Scan' ? 'ghost white' : 'ghost'}
              onClick={handleMenuClick}
            />
          </Left>
          <Middle>
            {mode === 'example' ? (
              <Title $inputmode={inputMode}>Example Problems</Title>
            ) : mode === 'new' ? (
              <TabPanel tabs={[{ name: 'Type', icon: 'keyboard' }, { name: 'Scan', icon: 'camera', disabled: promptLogin }]} onSelect={handleTabSelect} defaultTabName={inputMode} />
            ) : null}
          </Middle>
          <Right>
            <>
            {mode !== 'new' && (
              <Button
                size={'small'}
                icon={mode === 'solution' ? 'plus' : mode === 'example' ? 'delete' : ''}
                color={'ghost'}
                onClick={handleNewProblemClick}
              />
            )}
            {NEW_ACCOUNT_UI && (
              mode === 'new' && !loggedIn ? (
                <ButtonContainer>
                  <Button size={'tiny'} text={'Log In'} color={'ghost'} onClick={onLoginClick} />
                  <Button size={'tiny'} text={'Sign Up'} color={'black'} onClick={onSignUpClick} />
                </ButtonContainer>
              ) : mode === 'new' && loggedIn ? (
                <>
                  <Button 
                    size={'medium'} 
                    icon={'person-fill'} 
                    color={inputMode === 'Scan' ? 'ghost white' : 'ghost'} 
                    onClick={onAccountClick} 
                  />
                  <MenuOpen 
                    anchorEl={accountMenuAnchorEl} 
                    open={accountMenuOpen} 
                    options={accountMenuOptions}
                    color={inputMode === 'Scan' ? 'black' : 'white'}
                  />
                </>
              ) : null
            )}
            </>
          </Right>
        </>
      )}
    </TopContainer>
  );
};

export default TopBar;
