// Menu.tsx
import React, { useEffect, useState, useMemo } from 'react';
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import styled from 'styled-components';
import Fonts from './common/Fonts';
import Colors from './common/Colors';
import Icon from './common/Icon';
import Button from "../components/basic/Button";
import { useLogout, useGetUserInfo, getIsLoggedIn } from '../apollo/useAuth';
import { useNavigate } from 'react-router-dom';
import LegalFooter from './LegalFooter';
import { sortProblemsData } from '../utils/sortProblems';
import Loading from "./basic/LoadingDots";

const Container = styled.div`
  background-color: ${Colors.background};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 10px 10px 0px 10px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 54.5px;
  flex-shrink: 0;
  color: ${Colors.grey8};
  font-size: 20px;
  font-family: ${Fonts.quicksandSemiBold.fontFamily};
  font-weight: ${Fonts.quicksandSemiBold.fontWeight};
  padding: 0px 16px;
  margin: 0;
  gap: 10px;
`;

const TitleLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
`;

const SectionContainer = styled.div`
  margin: 0px 0px 24px 0px;
`;

const SectionHeader = styled.div`
  font-size: 12px;
  font-family: ${Fonts.quicksandBold.fontFamily};
  font-weight: ${Fonts.quicksandBold.fontWeight};
  color: ${Colors.grey4};
  text-align: left;
  padding: 0px 16px 4px 16px;
  margin: 0;
  text-transform: uppercase;
`;

const StyledList = styled(List)`
  flex-grow: 1;
  overflow-y: auto;
`;

const BaseListItem = styled(ListItem)`
  height: 36px; 
  position: relative;
  cursor: pointer;

  &:hover::after,
  &.selected::after {
    content: ' ';
    position: absolute;
    top: 1px;
    left: 0px;
    right: 0px;
    bottom: 1px;
    background-color: ${Colors.grey1};
    border-radius: 24px;
    z-index: 0;
  }

  & > .MuiListItemText-root {
    position: relative;
    z-index: 1;
  }
`;

const StyledListItem = styled(BaseListItem)``;

const IndividualItem = styled(BaseListItem)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  margin-top: 10px;
  gap: 10px;
`;

const StyledListItemText = styled(ListItemText)<{ $emphasize?: boolean; }>`
  & .MuiListItemText-primary {
    color: ${Colors.grey8};
    font-size: 16px;
    font-family: ${Fonts.quicksandMedium.fontFamily};
    font-weight: ${({ $emphasize }) => ($emphasize === true ? `${Fonts.quicksandSemiBold.fontWeight}` : `${Fonts.quicksandSemiLight.fontWeight}`)};
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }
  & .MuiListItemText-secondary { 
    color: ${Colors.grey6};
    font-size: 16px;
    font-family: ${Fonts.quicksandSemiLight.fontFamily};
    margin: 6px;
    white-space: nowrap;
  }
`;

const IconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const SubText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  flex-shrink: 0;
  color: ${Colors.grey6};
  font-size: 18px;
  font-family: ${Fonts.quicksandSemiLight.fontFamily};
  font-weight: ${Fonts.quicksandSemiLight.fontWeight};
  padding: 16px 16px;
  margin: 0;
  gap: 10px;
`;

const BottomContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  gap: auto;
  z-index: 10;
`;

const FooterContainer = styled.div`
  margin: 5px 0px 10px 0px;
`;

interface MenuProps {
  onSelect: (option: string) => void;
  open: boolean;
  onClose: () => void;
  isMobile: boolean;
  selectedId?: string;
  problemsData: { id: string, name: string, createdAt: string }[];
  userInfo: any;
}

const Menu: React.FC<MenuProps> = ({ onSelect, open, onClose, isMobile, selectedId, problemsData, userInfo }) => {
  const navigate = useNavigate();
  const { logout } = useLogout();
  const [menuLoaded, setMenuLoaded] = useState(false);

  // console.log('MENU PROBLEMS DATA', problemsData);

  useEffect(() => {
    if (problemsData.length >= 0 && userInfo !== undefined && userInfo !== null) {
      setMenuLoaded(true);
    }
  }, [problemsData, userInfo]);

  const menuSections = useMemo(() => {
    const formattedProblemsData = sortProblemsData(problemsData);
  
    const uniqueOlderDates = Array.from(new Set(
      formattedProblemsData
        .filter(problem => problem.formattedDate !== "Today" && problem.formattedDate !== "Yesterday")
        .map(problem => problem.formattedDate)
    ));
  
    return [
      {
        header: 'Today',
        options: formattedProblemsData
          .filter(problem => problem.formattedDate === "Today")
          .map(problem => ({ id: problem.id, name: problem.name, createdAt: problem.createdAt }))
      },
      {
        header: 'Yesterday',
        options: formattedProblemsData
          .filter(problem => problem.formattedDate === "Yesterday")
          .map(problem => ({ id: problem.id, name: problem.name, createdAt: problem.createdAt }))
      },
      ...uniqueOlderDates.map(date => ({
        header: date,
        options: formattedProblemsData
          .filter(problem => problem.formattedDate === date)
          .map(problem => ({ id: problem.id, name: problem.name, createdAt: problem.createdAt }))
      }))
    ].filter(section => section.options.length > 0);
  }, [problemsData]);

  const onLogoutClick = async () => {
    await logout();
    onClose();
    navigate("/problem");
    // navigate("/log-in");
  };

  const onSignUpClick = () => {
    navigate("/sign-up");
  };

  const onLogInClick = () => {
    navigate("/log-in");
  };

  const onNewProblemClick = () => {
    onSelect(null);
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      variant={isMobile ? "temporary" : "persistent"}
      PaperProps={{
        style: {
          width: '300px',
          maxWidth: '90%',
          // position: isMobile ? 'relative' : 'fixed',
          position: 'fixed',
          height: '100svh',
          margin: 0,
          top: 0,
          left: 0,
          zIndex: 1000,
          borderRight: `1px solid ${Colors.grey1}`,
          // border: 'none',
          // boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.06)'
        },
      }}
    >
      <Container>
          {/* <Title>
            {isMobile && (
                <Button size={'small'} icon={'delete'} color={'ghost'} onClick={onClose} />
            )}
            <div>PROBLEM HISTORY</div>
            <TitleLogo>
              <img src="seesolve-logo.svg" alt="SeeSolve Logo" style={{ width: '24px', height: '24px' }} />
              SEESOLVE.AI
            </TitleLogo>
          </Title> */}
          <IndividualItem onClick={onNewProblemClick} className={selectedId === "New Problem" ? 'selected' : ''}>
            <StyledListItemText primary="NEW PROBLEM" $emphasize={true} />
            <IconContainer>
              <Icon icon={'plus'} color={Colors.grey6} size={22} onClick={onNewProblemClick} />
            </IconContainer>
          </IndividualItem>
          <div style={{ height: '20px' }}></div>
        {getIsLoggedIn() ? (
          <>
            {menuLoaded === true ? (
              <StyledList>
                {menuSections.map((section) => (
                  <SectionContainer key={section.header}>
                    <SectionHeader>{section.header}</SectionHeader>
                    {section.options.map((option, index, array) => (
                      <StyledListItem
                        key={option.id}
                        onClick={() => onSelect(option.id)}
                        className={`${index === array.length - 1 ? 'last-item' : ''} ${selectedId === option.id ? 'selected' : ''}`}
                      >
                        <StyledListItemText primary={option.name} />
                        {/* <StyledListItemText primary={option.createdAt} /> */}
                      </StyledListItem>
                    ))}
                  </SectionContainer>
                ))}
              </StyledList>
            ) : (
              <>
                <Loading />
                <StyledList></StyledList>
              </>
            )}
            <IndividualItem onClick={onLogoutClick} className={selectedId === "Log Out" ? 'selected' : ''}>
              <StyledListItemText primary={userInfo?.getUserInfo.email} />
              {userInfo?.getUserInfo.email && (
                <IconContainer>
                  <StyledListItemText secondary="Log Out" />
                  <Icon icon={'logout'} color={Colors.grey6} size={22} onClick={onLogoutClick} />
                </IconContainer>
              )}
            </IndividualItem>
          </>
        ) : (
          <>
            <SubText>Join SeeSolve to save your problem history.</SubText>
            <StyledList></StyledList>
            <BottomContainer>
              <Button size={'small'} text={'Sign Up'} color={'black'} onClick={onSignUpClick} />
              <Button size={'small'} text={'Log In'} color={'ghost'} onClick={onLogInClick} />
            </BottomContainer>
          </>
        )}
        <FooterContainer><LegalFooter /></FooterContainer>
      </Container>
    </Drawer>
  );
};

export default Menu;
