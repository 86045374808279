// Answer.tsx
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from './common/Colors';
import Fonts from './common/Fonts';
import Button from './basic/Button';
import ButtonToggleGroup from './basic/ButtonToggleGroup';

const AnswerContainer = styled.div`
  width: 100%;
  background-color: ${Colors.white};
  margin-bottom: 20px;
  gap: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  font-size: 18px;
  color: ${Colors.grey8};
  font-family: ${Fonts.quicksandMedium.fontFamily};
  font-weight: ${Fonts.quicksandMedium.fontWeight};
  justify-content: flex-start;
  align-items: center;
`;

const FeedbackText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  color: ${Colors.grey6};
  font-family: ${Fonts.quicksandMedium.fontFamily};
  font-weight: ${Fonts.quicksandMedium.fontWeight};
  text-align: center;
  white-space: nowrap;
  gap: 8px;
`;

const FeedbackIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 14px);
`;

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  box-sizing: border-box;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 50px;
  gap: 10px;
  flex-wrap: wrap;
`;

const Right = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  min-height: 50px;
  gap: 10px;
  margin-left: auto;

  @supports (-webkit-line-clamp: 1) {
    /* If Left content is only one line, align Right side by side */
    flex-direction: row;
  }

  /* If Left has more than one line, move Right below */
  @supports not (-webkit-line-clamp: 1) {
    flex-direction: column;
  }

  /* If width small, center horizontally */
  @media (max-width: 400px) {
    justify-content: center;
    width: 100%;
    margin: auto;
  }
`;

const Only = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  min-height: 50px;
  width: 100%;
  gap: 10px;
`;

interface AnswerProps {
  multipleChoice: string[];
  multiselect: boolean;
  correctAnswer: any[] | any;
  vizUserAnswer: any[] | any;
  showFeedback: (feedback: boolean | null) => void;
  onCorrect: (isCorrect: boolean | null) => void;
  onNext: () => void;
  hasNextPart?: boolean;
}

const Answer: React.FC<AnswerProps> = ({ multipleChoice, multiselect, correctAnswer, vizUserAnswer, showFeedback, onCorrect, onNext, hasNextPart = false }) => {
  const [mode, setMode] = useState<'input' | 'feedback' | 'correct'>('input');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);

  const handleButtonClick = () => {
    if (mode === 'input') {
        let correct;
        if (multipleChoice) { // if UI answer multipleChoice, correctAnswer is an array
            correct = multiselect
              ? correctAnswer.length === selectedOptions.length && correctAnswer.every(v => selectedOptions.includes(v))
              : selectedOptions[0] === correctAnswer[0];
        } else { // if visualization interaction answer, either array of strings or single string
          // Case: Array of range objects
          if (Array.isArray(correctAnswer) && Array.isArray(vizUserAnswer) && correctAnswer.every(v => typeof v === 'object' && v.min !== undefined && v.max !== undefined)) {
            correct = correctAnswer.some(v => vizUserAnswer >= v.min && vizUserAnswer <= v.max);
          // Case: Single range object
          } else if (typeof correctAnswer === 'object' && correctAnswer.min !== undefined && correctAnswer.max !== undefined) { 
            correct = vizUserAnswer >= correctAnswer.min && vizUserAnswer <= correctAnswer.max;
          // Case: correctAnswer and vizUserAnswer are arrays of values
          } else if (Array.isArray(correctAnswer) && Array.isArray(vizUserAnswer) && correctAnswer.every(v => typeof v !== 'object')) {
            correct = JSON.stringify(correctAnswer) === JSON.stringify(vizUserAnswer);
          // Case: Single value comparison
          } else {
            correct = correctAnswer === vizUserAnswer;
          }
        }
        // console.log('correct answer', correctAnswer);
        // console.log('selected multipleChoice', selectedOptions);
        // console.log('correctAnswer', correctAnswer);
        // console.log('correctAnswer path', vizUserAnswer);
        // console.log('correct', correct);

        setValidation(correct);
        showFeedback(true);
        if (correct && hasNextPart === true) {
          setMode('correct');
        } else {
          setMode('feedback');
        }
    } else if (mode === 'feedback') {
        if (isCorrect && hasNextPart === true) {
            onNext();
        } else {  // Reset if wrong or no next part
            // setSelectedOptions([]);
            setValidation(null);
            showFeedback(null);
            setMode('input');
        }
    } else if (mode === 'correct') {
        onNext();
    }
  };

  const handleSelectionChange = (newSelectedOptions: string[]) => {
    setSelectedOptions(newSelectedOptions);
    if (mode === 'feedback' || mode === 'correct') {
        setMode('input');
        setValidation(null);
        showFeedback(null);
    }
  };

  const setValidation = (correct: boolean) => {
      setIsCorrect(correct);
      if (onCorrect) onCorrect(correct);
      return correct;
  };

  const showValidationFeedback = () => {
    return (
      <>
        {(mode === 'feedback' || mode === 'correct') && isCorrect !== null && isCorrect !== undefined &&
          <>
            <FeedbackText>
              <img src={isCorrect ? '/ui-assets/right.svg' : '/ui-assets/wrong.svg'} alt={isCorrect ? 'correct' : 'incorrect'} />
              {/* <span style={{ fontSize: '24px' }}>{isCorrect ? '✅' : '❌'}</span>
              <span style={{ fontSize: '18px' }}>{isCorrect ? 'Correct' : 'Not quite'}</span> */}
            </FeedbackText>
            {/* <FeedbackIcon>
              <img src={isCorrect ? '/ui-assets/right.svg' : '/ui-assets/wrong.svg'} alt={isCorrect ? 'correct' : 'incorrect'} />
            </FeedbackIcon> */}
          </>
        }
      </>
    );
  };  

  useEffect(() => {
    // if in feedback mode and no multipleChoice exist (aka visualization interaction for the answer)
    if (!multipleChoice && mode === 'feedback') {  // flip mode back to input when vizUserAnswer changes
      setMode('input');
      setValidation(null);
      showFeedback(null);
    }
  }, [JSON.stringify(vizUserAnswer)]);
      
  const labelText = multipleChoice ? `Choose your answer${multiselect ? '(s)' : ''}` : '';
  const getButtonConfig = () => {
    if (mode === 'input') return { text: 'Try It', icon: 'send-right', color: 'black' };
    if (mode === 'feedback') return { text: 'Try Again', icon: 'reset', color: 'white' };
    if (mode === 'correct') return { text: 'Next', icon: 'down', color: 'black' };
  };
  const { text: buttonText, icon: buttonIcon, color: buttonColor } = getButtonConfig();

  return (
      <AnswerContainer>
          {multipleChoice ? (
            <>
              <TextContainer>{labelText}</TextContainer>
              <InputContainer>
                  <Left>
                      <ButtonToggleGroup options={multipleChoice} multiselect={multiselect} onChange={handleSelectionChange} initialValue={[]} size={'medium'} />
                  </Left>
                  <Right>
                      {showValidationFeedback()}
                      <Button size={'medium'} text={buttonText} icon={buttonIcon} color={buttonColor} onClick={handleButtonClick} disabled={selectedOptions.length === 0} />
                  </Right>
              </InputContainer>
            </>
          ) : (
            <InputContainer>
              <Only>
                  {showValidationFeedback()}
                  <Button size={'medium'} text={buttonText} icon={buttonIcon} color={buttonColor} onClick={handleButtonClick} disabled={false} />
              </Only>
            </InputContainer>
          )}
      </AnswerContainer>
  );    
};

export default Answer;