// Login.tsx
import {FunctionComponent, useCallback, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Input from "../components/basic/Input";
import Button from "../components/basic/Button";
import ButtonLink from "../components/basic/ButtonLink";
import Colors from "../components/common/Colors";
import Fonts from "../components/common/Fonts";
import { useLogin } from "../apollo/useAuth";

const SignUpRoot = styled.div`
  width: 100%;
  height: 100svh;
  position: relative;
  background-color: ${Colors.background};
  box-shadow: 0px 3px 6px rgba(18, 15, 40, 0.12);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: ${Colors.grayDark};
  font-family: ${Fonts.quicksandMedium.fontFamily};
`;

const Logo = styled.img`
  width: 36px;
  height: 36px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
`;

const SignUpParent = styled.div`
  margin: 0;
  width: 100%;
  max-width: 320px;
  height: calc(100svh - 42px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  box-sizing: border-box;
  gap: 0px;
  z-index: 1;
`;

const SignUp1 = styled.h2`
  margin: 0;
  position: relative;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  font-family: ${Fonts.quicksandMedium.fontFamily};
  color: ${Colors.grayDark};
  text-align: left;
  padding-bottom: 26px;
`;

const Input1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;

const ButtonContainer = styled.div`
  padding: 14px 0px 10px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ForgotContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ForgotText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  cursor: text;
`;

const BottomFixed = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  bottom: 0px;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 10px 10px 10px 10px;
  max-width: 400px;
`;

const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const FooterText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  cursor: text;
`;

const LogIn: FunctionComponent = () => {
  const navigate = useNavigate();
  const { login, loginData, loginLoading, loginError } = useLogin();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onLoginClick = () => {
    setError(null);
    setIsLoading(true);
    login((email || "").toLowerCase(), password); // Call the login function with email and password
  };

  useEffect(() => {
    if (loginData && loginData.login.token) { // on successful login
      // console.log('logged in', loginData);
      navigate("/problem");
      setIsLoading(false);
    }
  }, [login, loginData, navigate]);

  useEffect(() => {
    if (loginError) {
      console.error('Login error:', loginError);
      // Handle login errors here, e.g., display a message to the user
      setError('password');
      setIsLoading(false);
    }
  }, [loginError]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onLoginClick();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onLoginClick]);

  const onSignUpClick = () => {
    navigate("/sign-up");
  };

  const onResetClick = () => {
    navigate("/forgot-password");
  };

  return (
    <SignUpRoot>
      <Logo src="/seesolve-logo.svg" alt="SeeSolve Logo" />
      <SignUpParent>
        <SignUp1>Log In</SignUp1>
        <Input1>
          <Input
            placeholder="Email"
            // propMinWidth="182px"
            icon={'mail'}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Input
            placeholder="Password"
            icon={'lock'}
            type={"password"}
            onChange={(event) => setPassword(event.target.value)}
            errorMessage={error == 'password' ? "Invalid email or password." : ''}
          />
        </Input1>
        <ButtonContainer>
          <Button text="LOG IN" onClick={onLoginClick} size={'small'} fixed={true} color={'black'} icon={isLoading ? 'loading' : undefined} /> 
        </ButtonContainer>
        <ForgotContainer>
          <ForgotText>Forgot your password?</ForgotText>
          <ButtonLink text="Reset" onClick={onResetClick} />
        </ForgotContainer>
      </SignUpParent>
      <BottomFixed>
        <BottomContainer>
          <FooterText>Don't have an account?</FooterText>
          <ButtonLink text="Sign Up" onClick={onSignUpClick} />
        </BottomContainer>
      </BottomFixed>
    </SignUpRoot>
  );
};

export default LogIn;
